import { Box, Button, Typography } from '@material-ui/core';
import { Mail } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Questions = () => {
  const { t } = useTranslation();

  return (
    <Box alignItems="center" display="flex" flexDirection="column" py={6}>
      <Typography align="center" gutterBottom variant="h5">
        {t('landingPage.questions')}
      </Typography>
      <Box py={2} />
      <Button
        color="primary"
        href="mailto:yasin.guezeldal+chowgrabber@robinheat.de"
        size="large"
        startIcon={<Mail />}
        variant="contained"
      >
        {t('cta.getInTouch')}
      </Button>
    </Box>
  );
};
