import firebase from 'firebase';
import React from 'react';
import ReactDOM from 'react-dom';

import { Api } from './api';
import { App } from './components/App';
import firebaseConfig from './firebaseConfig.json';
import * as serviceWorker from './serviceWorker';
import './i18n';
import './index.css';

const app = firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <Api initializedApp={app}>
      <App />
    </Api>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
