import { makeStyles } from '@material-ui/core/styles';

export const useLanguageSelectorStyles = makeStyles((theme) => ({
  popoverZIndex: {
    zIndex: theme.zIndex.modal + 1,
  },
  languageSelectorAlign: {
    alignSelf: 'flex-start',
  },
}));
