import { Box, Container, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { useApi } from '../../api';
import { CookieConsentManager } from '../CookieConsentManager';
import { Hero } from '../Hero';

import { HowItWorks, MockupsSection, Questions } from '.';

export const LandingPage = () => {
  const [cookies, setCookies] = useCookies(['gdprchowgrabber']);
  const { setAnalyticsEnabled } = useApi();
  const { t } = useTranslation();

  const handleOnSubmit = ({
    performanceCookieActive,
  }: {
    performanceCookieActive: boolean;
  }) => {
    setCookies('gdprchowgrabber', performanceCookieActive);
  };

  useEffect(() => {
    if (!setAnalyticsEnabled) return;

    if (cookies.gdprchowgrabber === 'true') {
      setAnalyticsEnabled(true);
    } else setAnalyticsEnabled(false);
  }, [cookies, setAnalyticsEnabled]);

  return (
    <>
      <Hero />
      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" component="h2" gutterBottom variant="h4">
            {t('landingPage.betaTest.title')}
          </Typography>
          <Typography align="center" gutterBottom>
            {t('landingPage.betaTest.description')}
          </Typography>
          <Box marginY={4}>
            <Grid container>
              <Grid
                alignItems="center"
                container
                item
                justify="center"
                sm={6}
                xs={12}
              >
                <Box paddingY={2}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.robinheat.chowgrabber"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      alt="google-play-badge"
                      height={100}
                      src="images/google-play-badge.png"
                    />
                  </a>
                </Box>
              </Grid>
              <Grid
                alignItems="center"
                container
                item
                justify="center"
                sm={6}
                xs={12}
              >
                <Box paddingY={2}>
                  <a
                    href="https://apps.apple.com/us/app/id1543527123"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      alt="app-store-badge"
                      src="images/app-store-badge.svg"
                      width={200}
                    />
                  </a>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box bgcolor="#f2f2f2" py={4}>
        <MockupsSection />
      </Box>
      <Box py={4}>
        <HowItWorks />
      </Box>
      <Box bgcolor="#f2f2f2" py={4}>
        <Questions />
      </Box>
      <CookieConsentManager onSubmit={handleOnSubmit} />
    </>
  );
};
