import { Button, Menu, MenuItem, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LanguageIcon from '@material-ui/icons/Language';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { resources } from '../../i18n';

import { useLanguageSelectorStyles } from '.';

export const LanguageSelector: React.FC<{}> = () => {
  const [anchorEl, setAnchorEl] = React.useState<
    EventTarget & HTMLButtonElement
  >();
  const { i18n } = useTranslation();
  const className = useLanguageSelectorStyles();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleLanguageSelect = useCallback(
    (lang) => () => {
      i18n.changeLanguage(lang);
      handleClose();
    },
    [i18n],
  );

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        aria-label="language-selector"
        color="inherit"
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        size="small"
        startIcon={<LanguageIcon />}
      >
        <Typography variant="body2">
          {Object.keys(resources).includes(i18n.language) &&
            resources?.[i18n.language].shortName}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={className.popoverZIndex}
        getContentAnchorEl={null}
        id="simple-menu"
        keepMounted
        onClose={handleClose}
        open={!!anchorEl}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {Object.keys(resources).map((language) => (
          <MenuItem
            aria-label={language}
            className={className.popoverZIndex}
            key={language}
            onClick={handleLanguageSelect(language)}
          >
            {resources?.[language].nativeName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
