import { Box, Divider, Grid, Link, Typography } from '@material-ui/core';
import React from 'react';

import { LanguageSelector } from '../LanguageSelector';

import { useFooterStyles } from './useFooterStyles';

export const Footer = () => {
  const classNames = useFooterStyles();

  return (
    <footer className={classNames.footer}>
      <Box p={2}>
        <Grid container justify="space-between">
          <Grid item xs={6}>
            <Typography>
              © 2020 Chow Grabber a division of West Launch LLC
            </Typography>
          </Grid>
          <Grid
            alignItems="center"
            container
            item
            justify="flex-end"
            spacing={4}
            xs={6}
          >
            <Grid item>
              <Grid className={classNames.linksContainer} container spacing={2}>
                <Grid className={classNames.linksItem} item>
                  <Link
                    href="https://app.termly.io/document/terms-of-use-for-website/bce2b894-5590-4929-abf9-d4a18afe3a39"
                    target="_blank"
                  >
                    Terms and Conditions
                  </Link>
                </Grid>
                <Divider
                  className={classNames.verticalSpacer}
                  flexItem
                  orientation="vertical"
                />
                <Grid className={classNames.linksItem} item>
                  <Link
                    href="https://app.termly.io/document/privacy-policy/d6530b8a-17d2-4b8a-a833-7a66a7695bfa"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                </Grid>
                <Divider
                  className={classNames.verticalSpacer}
                  flexItem
                  orientation="vertical"
                />
                <Grid className={classNames.linksItem} item>
                  <Link
                    href="https://app.termly.io/document/disclaimer/4d619a8a-8569-441f-b5ce-526c19537fac"
                    target="_blank"
                  >
                    Disclaimer
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <LanguageSelector />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </footer>
  );
};
