import { Grid, Switch, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useCookieConsentManagerStyles } from './useCookieConsentManagerStyles';

export interface Props {
  cookieType: 'nonOptional' | 'performance';
  onToggleOption?: (newState: boolean) => void;
  isActive: boolean;
  isEnabled?: boolean;
}

export const CookieTypeItem: React.FC<Props> = ({
  cookieType,
  onToggleOption,
  isActive,
  isEnabled = true,
}) => {
  const classNames = useCookieConsentManagerStyles();
  const { t } = useTranslation();

  const handleOnChange = useCallback(
    (event) => {
      if (!onToggleOption) return;
      const {
        target: { checked: nextState },
      } = event;

      onToggleOption(nextState);
    },
    [onToggleOption],
  );

  return (
    <Grid container>
      <Grid className={classNames.optInRowItem} item lg={1}>
        <Switch
          checked={isActive}
          color="secondary"
          disabled={!isEnabled}
          onChange={handleOnChange}
        />
      </Grid>
      <Grid className={classNames.optInRowItem} item lg={2}>
        <Typography>
          {t(`cookieConsent.cookies.${cookieType}.label`)}
        </Typography>
      </Grid>
      <Grid className={classNames.optInRowItem} item lg={9}>
        <Typography>
          {t(`cookieConsent.cookies.${cookieType}.description`)}
        </Typography>
      </Grid>
    </Grid>
  );
};
