import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import logo from '../../images/logo.svg';
import { LanguageSelector } from '../Footer';

import { useHeroStyles } from './useHeroStyles';

export const Hero = () => {
  const classNames = useHeroStyles();
  const { t } = useTranslation();

  return (
    <div className={classNames.hero}>
      <header className={classNames.pageHeader}>
        <Container>
          <Box className={classNames.container}>
            <a href="/">
              <img
                alt="ChowGrabber Logo"
                className={classNames.logo}
                src={logo}
              />
            </a>
            <LanguageSelector />
          </Box>
        </Container>
      </header>
      <Box className={classNames.heroContent}>
        <Typography align="center" component="h1" gutterBottom variant="h4">
          {t('landing.header')}
        </Typography>
        <Typography align="center" gutterBottom variant="h5">
          {t('landing.subheader')}
        </Typography>
        <Box py={2} />
      </Box>
    </div>
  );
};
