import React from 'react';
import { CookiesProvider } from 'react-cookie';

import { Footer } from '../Footer';
import { LandingPage } from '../LandingPage';

export const App = () => {
  return (
    <>
      <CookiesProvider>
        <LandingPage />
        <Footer />
      </CookiesProvider>
    </>
  );
};
