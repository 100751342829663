import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useHeroStyles = makeStyles((theme: Theme) =>
  createStyles({
    hero: {
      height: '70vh',
      backgroundColor: 'rgb(255,106,131)',
      clipPath: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
      background:
        'linear-gradient(176deg, rgba(255,106,131,1) 0%, rgba(249,136,117,1) 60%, rgba(243,168,102,1) 100%)',

      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      position: 'relative',
    },
    heroCta: {
      color: 'rgb(255,106,131)',
    },
    container: { display: 'flex', justifyContent: 'space-between' },
    pageHeader: {
      padding: '1rem 0',
    },
    logo: {
      height: '2rem',
    },
    heroContent: {
      height: '70%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',

      '& h1': {
        fontWeight: 500,
      },
    },
    mockup: {
      width: '100%',
      maxWidth: '320px',
    },
    footer: {
      padding: '2rem',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  }),
);
