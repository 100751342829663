import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import heroImage from '../../images/hero2.jpg';

export const useAppStyles = makeStyles((theme: Theme) =>
  createStyles({
    hero: {
      height: '70vh',
      backgroundImage: `url(${heroImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '30% top',
      display: 'flex',
      flexDirection: 'column',
    },
    pageHeader: {
      padding: '1rem 0',
    },
    logo: {
      height: '4rem',
    },
    heroContent: {
      height: '70%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '& h1': {
        fontWeight: 500,
      },

      '& h1, & h4': {
        textShadow: `0 0 50px ${theme.palette.background.paper}`,
      },
    },
    mockup: {
      width: '100%',
      maxWidth: '320px',
    },
    footer: {
      padding: '2rem',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  }),
);
