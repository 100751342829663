import firebase from 'firebase';
import React, { useState } from 'react';

export interface SimpleTenant {
  email: string;
}

export const ApiContext = React.createContext<ApiContextInterface | undefined>(
  undefined,
);

export interface ApiContextInterface {
  auth: firebase.auth.Auth;
  firestore: firebase.firestore.Firestore;
  signupTenant: (simpleTenant: SimpleTenant) => Promise<void>;
  analytics: firebase.analytics.Analytics;
  setAnalyticsEnabled: (enabled: boolean) => void;
}

interface Props {
  initializedApp: firebase.app.App;
  children: React.ReactNode;
}

export const Api: React.FC<Props> = ({ children, initializedApp }: Props) => {
  const auth = initializedApp.auth();
  const firestore = initializedApp.firestore();
  const [analytics, setAnalytics] = useState<firebase.analytics.Analytics>();

  try {
    firebase.analytics.isSupported().then((isSupported: boolean) => {
      if (isSupported) {
        setAnalytics(initializedApp.analytics());
      }
    });
  } catch (err) {
    console.error(err);
  }

  const signupTenant = (simpleTenant: SimpleTenant): Promise<void> =>
    new Promise<void>((resolve, reject) => {
      const landigPageSignupCollection = firestore.collection(
        'landingPageSignup',
      );

      return landigPageSignupCollection
        .add(simpleTenant)
        .then(() => resolve())
        .catch(reject);
    });

  const setAnalyticsEnabled = (enabled: boolean) => {
    if (!analytics) return;
    analytics.setAnalyticsCollectionEnabled(enabled);
  };

  return (
    <ApiContext.Provider
      value={{ auth, firestore, signupTenant, setAnalyticsEnabled, analytics }}
    >
      {children}
    </ApiContext.Provider>
  );
};
