import { makeStyles } from '@material-ui/core/styles';

export const useFooterStyles = makeStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  linksContainer: {
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  linksItem: {
    [theme.breakpoints.only('xs')]: {
      textAlign: 'right',
    },
  },
  verticalSpacer: {
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
}));
