import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import useCookies from 'react-cookie/cjs/useCookies';
import { useTranslation } from 'react-i18next';

import { CookieTypeItem } from './CookieTypeItem';
import { useCookieConsentManagerStyles } from './useCookieConsentManagerStyles';

export interface Props {
  onSubmit: ({
    performanceCookieActive,
  }: {
    performanceCookieActive: boolean;
  }) => void;
}

export const CookieConsentManager: React.FC<Props> = ({ onSubmit }) => {
  // helper
  const classNames = useCookieConsentManagerStyles();
  const { t } = useTranslation();
  const [cookies] = useCookies(['gdprchowgrabber']);
  const [submitted, setSubmitted] = useState<boolean>(false);

  // State
  const [
    performanceCookieActive,
    setPerformanceCookieActive,
  ] = useState<boolean>(false);
  const [isExtendedMode, setIsExtendedMode] = useState<boolean>(false);

  // Callbacks
  const isCookieSet = cookies.gdprchowgrabber;

  const handleSubmit = () => {
    onSubmit({
      performanceCookieActive,
    });
    setSubmitted(true);
  };

  const handleAcceptAllSubmit = () => {
    onSubmit({
      performanceCookieActive: true,
    });
    setSubmitted(true);
  };

  if (submitted || isCookieSet) return null;

  return (
    <Paper className={classNames.mainWrapper} elevation={3}>
      <Card square>
        <CardHeader
          className={classNames.subheader}
          subheader={
            <>
              {t('cookieConsent.subheader')}{' '}
              <Link
                className={classNames.link}
                href="https://app.termly.io/document/cookie-policy/4a7b4151-7ae0-40b2-9341-0297d15e9392"
              >
                {t('footer.privacy')}
              </Link>
            </>
          }
          title={t('cookieConsent.header')}
        />
        <CardContent>
          {isExtendedMode ? (
            <Grid className={classNames.contentWrapper} container>
              <Grid item lg={10}>
                <CookieTypeItem
                  cookieType="nonOptional"
                  isActive
                  isEnabled={false}
                />
                <CookieTypeItem
                  cookieType="performance"
                  isActive={performanceCookieActive}
                  onToggleOption={setPerformanceCookieActive}
                />
              </Grid>
              <Grid className={classNames.actionWrapper} item lg={2}>
                <Button
                  color="secondary"
                  onClick={handleSubmit}
                  variant="contained"
                >
                  {t('cookieConsent.buttons.accept')}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <div className={classNames.submitCompactWrapper}>
              <Button
                color="secondary"
                onClick={handleAcceptAllSubmit}
                variant="contained"
              >
                {t('cookieConsent.buttons.acceptAll')}
              </Button>
              <div className={classNames.horizontalSpacer} />
              <Button
                className={classNames.link}
                onClick={() => setIsExtendedMode(true)}
              >
                {t('cookieConsent.buttons.manage')}
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </Paper>
  );
};
