import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const HowItWorks = () => {
  const { t } = useTranslation();

  return (
    <Box py={6}>
      <Container maxWidth="sm">
        <Typography align="center" gutterBottom variant="h4">
          {t('landingPage.howItWorks.title')}
        </Typography>
        <Box py={2} />
        <Typography gutterBottom variant="h5">
          {t('landingPage.howItWorks.one.title')}
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          {t('landingPage.howItWorks.one.content')}
        </Typography>
        <Typography gutterBottom variant="h5">
          <span>{t('landingPage.howItWorks.two.title')}</span>
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          {t('landingPage.howItWorks.two.content')}
        </Typography>
        <Typography gutterBottom variant="h5">
          {t('landingPage.howItWorks.three.title')}
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="subtitle1">
          {t('landingPage.howItWorks.three.content')}
        </Typography>
      </Container>
    </Box>
  );
};
