import { makeStyles } from '@material-ui/core/styles';

export const useCookieConsentManagerStyles = makeStyles((theme) => {
  const customBackgroundColor = '#2B373B';
  const {
    palette: {
      primary: { contrastText },
      secondary: { main: linkColor },
    },
  } = theme;

  return {
    mainWrapper: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      '& .MuiPaper-root': {
        backgroundColor: customBackgroundColor,
        color: contrastText,
      },
      zIndex: theme.zIndex.drawer + 2,
    },
    contentWrapper: {
      alignItems: 'center',
    },
    actionWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    subheader: {
      '& .MuiCardHeader-subheader': {
        color: contrastText,
      },
    },
    link: {
      color: linkColor,
    },
    submitCompactWrapper: {
      display: 'flex',
      margin: '0 1rem',
    },
    horizontalSpacer: {
      width: '1rem',
    },
    optInRowItem: {
      display: 'flex',
      alignItems: 'center',
      margin: '0.5rem 0',
    },
  };
});
