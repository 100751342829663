import firebase from 'firebase';
import React from 'react';

import { ApiContext, ApiContextInterface } from '.';

const { useContext } = React;

export const useApi = (): ApiContextInterface => useContext(ApiContext);

export const useAuth = (): firebase.auth.Auth => useContext(ApiContext).auth;

export const useAnalytics = (): firebase.analytics.Analytics =>
  useContext(ApiContext).analytics;
