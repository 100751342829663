import { Box, Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import mockupMap from '../../images/mockup-map.png';
import mockupOpen from '../../images/mockup-open.png';

import { useLandingPageStyles } from '.';

export const MockupsSection = () => {
  const classNames = useLandingPageStyles();
  const { t } = useTranslation();

  return (
    <Container>
      <Box py={6}>
        <Typography align="center" component="h2" gutterBottom variant="h4">
          {t('landingPage.mockups.title')}
        </Typography>
        <Box py={2} />
        <Grid alignItems="center" container justify="center" spacing={4}>
          <Grid item sm={6} xs={12}>
            <Typography gutterBottom variant="h5">
              {t('landingPage.mockups.map.title')}
            </Typography>
            <Typography gutterBottom>
              {t('landingPage.mockups.map.section')}
            </Typography>
          </Grid>
          <Grid item>
            <img
              alt="food truck profile on map"
              className={classNames.mockup}
              src={mockupOpen}
            />
          </Grid>
        </Grid>
        <Grid
          alignItems="center"
          container
          direction="row-reverse"
          justify="center"
          spacing={4}
        >
          <Grid item sm={6} xs={12}>
            <Typography gutterBottom variant="h5">
              {t('landingPage.mockups.second.title')}
            </Typography>
            <Typography gutterBottom>
              {t('landingPage.mockups.second.section1')}
            </Typography>
            <Typography>{t('landingPage.mockups.second.section2')}</Typography>
          </Grid>
          <Grid item>
            <img
              alt="set food truck open"
              className={classNames.mockup}
              src={mockupMap}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
