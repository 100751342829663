import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationES from './locales/es.json';
import translationKO from './locales/ko.json';
import translationZH from './locales/zh.json';

export const resources = {
  'en-US': {
    translation: translationEN,
    nativeName: 'English',
    shortName: 'EN',
  },
  es: {
    translation: translationES,
    nativeName: 'Español',
    shortName: 'ES',
  },
  ko: {
    translation: translationKO,
    nativeName: '한국인',
    shortName: 'KO',
  },
  zh: {
    translation: translationZH,
    nativeName: '中国',
    shortName: 'ZH',
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    whitelist: Object.keys(resources),
    fallbackLng: 'en-US',
    load: 'currentOnly',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
